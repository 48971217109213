import { ApplicationController } from './application_controller'

async function revealOpenedEvent(event) {
  let modalDialog = event?.target
  let modalDialogID = modalDialog?.id
  if(!modalDialogID){ return }

  let currentURL = new URL(window.location.href)
  currentURL.searchParams.set(`navigable_dialog_id`, modalDialogID)
  window.history.replaceState({modalDialogID: modalDialogID}, "", currentURL.toString())

  modalDialog.dataset.navigableDialogOpen = true
}

export default class extends ApplicationController {
  static values = {
    open: Boolean
  }

  connect(){
    if(!this.eventConnected) {
      $(this.element).on('open.zf.reveal', revealOpenedEvent);
      this.eventConnected = true

      if(this.openValue){
        this.autoOpen()
      }
    }
  }

  autoOpen(){
    var elem = new Foundation.Reveal($(this.element));
    elem.open()
  }
}
