/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
App.StripeElements = class StripeElements {
  constructor(selector) {
    this.selector = selector;
    if (!$('#card-element').length && !$('#attendance_fee_stripe_payment_intent_id').length) { return; }
    this.form = $(this.selector);

    // initialize Elements
    // include both punchpass publishable key and client connect account id
    if (typeof Stripe !== 'undefined') {
      this.stripe = Stripe(stripeKey, {stripeAccount: stripeAccountKey});
      this.elements = this.stripe.elements();
    }
  }

  renderElements() {
    if (!$('#card-element').length) { return; }
    const _this = this;

    // design Elements
    const style = { base: {
      color: '#555555',
      fontFamily: 'Lato, Helvetica, Roboto, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      lineHeight: '24px',
      '::placeholder': { color: '#999'
    },
      ':-webkit-autofill': { color: '#fce883'
    }
    }
  };

    // create Elements
    const card = this.elements.create('card', {
      hidePostalCode: true,
      iconStyle: 'default',
      style
    });

    // mount Elements in current DOM
    // card.mount '#card-element'
    card.mount(this.form.find('#card-element')[0]);

    // show error when required
    card.addEventListener('change', function(event) {
      if (event.error) {
        _this.showCardError(event.error.message);
      } else {
        _this.showCardError('');
      }
    });

    // catch submit action on the form
    return this.form.on('submit', function(event) {
      // don't catch action if the token has been generated
      if (_this.form.find('input[name="stripeToken"]').length) { return; }
      if (_this.form.find('#card-element').length === 0) { return; }
      if (!_this.form.find('#card-element').is(':visible')) { return; }

      // don't catch action if the payment is processed offline
      const paidOnlineFlag = _this.form.find('input[data-behavior~="paid-online"]');
      if (paidOnlineFlag.length && !paidOnlineFlag.is(':checked')) { return; }

      event.preventDefault();

      // make sure that the submit button is disabled
      // (useful if the form is submitted with <Enter>)
      const submitButtons = _this.form.find('button[type="submit"]');
      if (submitButtons.length === 1) {
        Ladda.create(submitButtons[0]).start();
      } else {
        // play nice with multiple submit buttons (eg. 'Assign Pass' form)
        submitButtons.prop('disabled', true);
        const commitInputValue = _this.form.find('input[name="commit"]').val();
        const commitButton = _this.form.find('#' + commitInputValue + '-button');
        submitButtons.each(index => Ladda.create(submitButtons[index]).start());
      }

      const options = _this.setOptions();

      if (window.location.pathname.includes('memberships')) {
        return fetch('/stripe/setup_intents', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'
        },
          body: JSON.stringify({
            authenticity_token: $('meta[name=csrf-token]').attr('content'),
            initial_membership_values: $('#initial_membership_values').val(),
            membership_id: $('#membership_id').val()
          })
        }
        )
        .then(response => response.json())
        .then(parsedResponse => _this.stripe.confirmCardSetup(parsedResponse.clientSecret, {payment_method: {card}}).then(function(result) {
          if (result.error) {
            // The setup has failed

            // show raised error and re-enable submit buttons
            _this.showCardError(result.error.message);
            return Ladda.stopAll();
          } else {
            // The setup has succeeded

            $('#stripe_payment_method_id').val(result.setupIntent.payment_method);
            // Clear the paid_with field
            if ($("#membership_paid_with").length > 0) {
              $("#membership_paid_with")[0].selectize.clear();
            }
            // Update the stripe_setup_confirmed hidden field
            if ($("#stripe_setup_confirmed").length > 0) {
              $("#stripe_setup_confirmed").val("1");
            }
            // Submit the form
            return _this.form.unbind().submit();
          }
        }));
      } else {
        return fetch('/stripe/payment_intents', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json'
        },
          body: JSON.stringify({
            authenticity_token: $('meta[name=csrf-token]').attr('content'),
            initial_pass_values: $('#initial_pass_values').val()
          })
        }
        )
        .then(async (response) => {
          const result = await response.json()

          if(result.error) {
            _this.showCardError(result.error.message);
            return Ladda.stopAll();
          }

          return result
        })
        .then(parsedResponse => _this.stripe.confirmCardPayment(parsedResponse.clientSecret, {payment_method: {card}, setup_future_usage: 'off_session'}).then(function(result) {
          if (result.error) {
            // The payment has failed

            // show raised error and re-enable submit buttons
            _this.showCardError(result.error.message);
            return Ladda.stopAll();
          } else if (result.paymentIntent && (result.paymentIntent.status === 'succeeded')) {
            // The payment has succeeded

            // Clear the paid_with field
            if ($("#pass_paid_with").length > 0) {
              $("#pass_paid_with")[0].selectize.clear();
            }
            // Update the stripe_payment_confirmed hidden field
            if ($("#stripe_payment_confirmed").length > 0) {
              $("#stripe_payment_confirmed").val("1");
            }
            // Update the stripe_payment_intent_id hidden field
            if ($("#pass_stripe_payment_intent_id").length > 0) {
              $("#pass_stripe_payment_intent_id").val(result.paymentIntent.id);
            }
            if ($("#reservation_stripe_payment_intent_id").length > 0) {
              $("#reservation_stripe_payment_intent_id").val(result.paymentIntent.id);
            }
            // Submit the form
            return _this.form.unbind().submit();
          }
        }));
      }
    });
  }

  renderBancontactButton() {
    if (!$('#bancontact-button').length) { return; }
    const _this = this;
    return $('#bancontact-button').click(function(e) {
      e.preventDefault();
      if (!_this.form[0].checkValidity()) {
        return _this.form[0].reportValidity();
      } else {
        $(this).html('Processing...');
        _this.form.find('.button').attr('disabled', true);
        _this.form.find('.button').prop('disabled', true);
        $('#purchase_status').val('pending');
        return $.ajax({
          method: 'post',
          url: purchaseUrl,
          data: _this.form.serialize(),
          dataType: 'json',
          success(data) {
            if (data.stripe_source_id) {
              const sourceResult = _this.stripe.retrieveSource({
                id: data.stripe_source_id,
                client_secret: data.stripe_client_secret}).then(function(result) {
                  if (result.error) {
                    Honeybadger.notify(result.error.message, {context: {error: result.error}});
                    $('#bancontact-form-error').html(result.error.message);
                    return $('#bancontact-button').html('Pay with Bancontact');
                  } else {
                    switch (result.source.flow) {
                      case 'redirect':
                        $('#bancontact-button').html('Redirecting...');
                        return window.location.replace(result.source.redirect.url);
                    }
                  }
              });
            } else {
              // fully discounted purchase
              _this.form.find('#card-element').remove();
              _this.form.submit();
            }
          },
          error(xhr) {
            $('#purchase_status').val('received');
            $('#bancontact-form-error').html(JSON.parse(xhr.responseText).message);
            $('#bancontact-button').html('Pay with Bancontact');
            _this.form.find('.button').attr('disabled', false);
            _this.form.find('.button').prop('disabled', false);
          }
        });
      }
    });
  }

  renderPaymentRequestButton() {
    if (!$('#payment-request').length) { return; }
    const _this = this;

    // initialize PR button
    // country: country of the Stripe account
    //   The `country` parameter is used to set the `supportedNetworks` parameter
    //   of the Payment Request API.
    // currency: currency for this company online payments
    const paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: stripeCurrency,
      total: {
        label: this.form.data('payment-label'),
        amount: this.form.data('payment-amount')
      }
    });

    // create PR button
    const prButton = this.elements.create('paymentRequestButton', {
      paymentRequest,
      style: { paymentRequestButton: {
        type: 'default',
        theme: 'light-outline',
        height: '50.59px'
      }
    }
    } // same height than a standard button
    );

    // if user can make a payment (Chrome, Apple Pay or Android Pay available)
    paymentRequest.canMakePayment().then(function(result) {
      if (result) {
        // show the #payment-request part of the form
        $('#payment-request').slideDown();
        // mount PR button in DOM
        prButton.mount('#payment-request-button');
      }
    });

    // catch Stripe token
    return paymentRequest.on('token', function(ev) {
      // append stripToken input to form
      _this.addTokenInput(ev.token.id, _this.form.attr('id'));
      // send data with an Ajax call
      $.ajax({
        method: _this.form.data('pr-method'),
        url: _this.form.attr('action'),
        data: _this.form.serialize(),
        dataType: 'json',
        success(data) {
          ev.complete('success');
          // replace form with loader
          _this.form.replaceWith(` \
<div class="text-center"> \
<div class="spacer-2"></div> \
<div class="spacer-2"></div> \
<i class="fa fa-spin fa-circle-o-notch fa-2x medium-gray"></i> \
<div class="spacer-2"></div> \
</div>`);
          // redirect on success
          window.location = data.location;
        },
        error(xhr) {
          console.log(xhr.responseText);
          // show error on failure
          $('#payment-error').html(JSON.parse(xhr.responseText).errors);
          ev.complete('fail');
        }
      });
    });
  }

  // For charging fees on late cancel/no show attendances.
  setListenerForAttendanceFeeCharger() {
    if (!$('#attendance_fee_stripe_payment_intent_id').length) { return; }
    const _this = this;

    // catch submit action on the form
    return this.form.on('submit', function(event) {
      event.preventDefault();

      // make sure that the submit button is disabled
      // (useful if the form is submitted with <Enter>)
      const submitButtons = _this.form.find('button[type="submit"]');
      if (submitButtons.length === 1) {
        Ladda.create(submitButtons[0]).start();
      } else {
        // play nice with multiple submit buttons (eg. 'Assign Pass' form)
        submitButtons.prop('disabled', true);
        const commitInputValue = _this.form.find('input[name="commit"]').val();
        const commitButton = _this.form.find('#' + commitInputValue + '-button');
        submitButtons.each(index => Ladda.create(submitButtons[index]).start());
      }

      const options = _this.setOptions();

      return fetch('/stripe/payment_intents', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json'
      },
        body: JSON.stringify({
          authenticity_token: $('meta[name=csrf-token]').attr('content'),
          attendance_id: $('#attendance_id').val()
        })
      }
      )
      .then(response => response.json())
      .then(parsedResponse => _this.stripe.confirmCardPayment(parsedResponse.clientSecret).then(function(result) {
        if (result.error) {
          // The payment has failed

          // show raised error and re-enable submit buttons
          _this.showCardError(result.error.message);
          return Ladda.stopAll();
        } else if (result.paymentIntent && (result.paymentIntent.status === 'succeeded')) {
          // The payment has succeeded

          // Update the stripe_payment_intent_id hidden field
          if ($("#attendance_fee_stripe_payment_intent_id").length > 0) {
            $("#attendance_fee_stripe_payment_intent_id").val(result.paymentIntent.id);
          }
          // Submit the form
          return _this.form.unbind().submit();
        }
      }));
    });
  }

  setOptions() {
    let options;
    const _this = this;
    // fill options with customer data
    if (_this.form.data('stripe-name') !== undefined) {
      // options for logged-in customer
      options = {
        name: _this.form.data('stripe-name'),
        address_line1: _this.form.find('#customer_street_address').val(),
        address_city: _this.form.find('#customer_city').val(),
        address_state: _this.form.find('#customer_state').val(),
        address_zip: _this.form.find('#customer_zip_code').val(),
        address_country: _this.form.find('#customer_country').val()
      };
    } else {
      // options for visitor on public pages
      options = {
        name: _this.form.find('#purchase_email').val() ||
          _this.form.find('#customer_email').val(),
        address_line1: _this.form.find('#purchase_street_address').val() ||
          _this.form.find('#customer_street_address').val(),
        address_city: _this.form.find('#purchase_city').val() ||
          _this.form.find('#customer_city').val(),
        address_state: _this.form.find('#purchase_state').val() ||
          _this.form.find('#customer_state').val(),
        address_zip: _this.form.find('#purchase_zip_code').val() ||
          _this.form.find('#customer_zip_code').val(),
        address_country: _this.form.find('#purchase_country').val() ||
          _this.form.find('#customer_country').val()
      };
    }
    return options;
  }

  // function used to show errors for the Elements form
  showCardError(message) {
    return this.form.find('#card-errors').html(message);
  }

  // function used to append stripeToken input to form
  addTokenInput(token_value, formId) {
    return $('<input>').attr({
      type: 'hidden',
      name: 'stripeToken',
      value: token_value}).appendTo(this.form);
  }
};

$(document).ready(function() {
  if (!($('[data-behavior~=form-with-stripe-elements]').length > 0)) { return; }
  window.stripeElementsForm = [];
  return $('[data-behavior~=form-with-stripe-elements]').each(function(index) {
    window.stripeElementsForm[index] =
      new App.StripeElements($(this));
    if (typeof window.stripeElementsForm[index].stripe !== 'undefined') {
      window.stripeElementsForm[index].renderElements();
      window.stripeElementsForm[index].renderPaymentRequestButton();
      window.stripeElementsForm[index].renderBancontactButton();
      return window.stripeElementsForm[index].setListenerForAttendanceFeeCharger();
    }
  });
});
