/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= require pickadate/lib/compressed/picker
//= require pickadate/lib/compressed/picker.date

require('../vendor/daterangepicker');

App.DatePicker = class DatePicker {
  constructor(selector) {
    this.selector = selector;
    this.datePickerElements = $(this.selector);
  }

  render() {
    const _this = this;
    return this.datePickerElements.each(function() {
      if ($(this).data('mode') === 'range') {
        return _this.renderRange($(this));
      } else {
        return _this.renderSingle($(this));
      }
    });
  }

  renderSingle(datePickerElement) {
    const _this = this;
    this.options = {
      firstDay: companyCalendarStart === 'Monday',
      format: 'mmmm d, yyyy',
      min: datePickerElement.data('min'),
      max: datePickerElement.data('max'),
      selectMonths: datePickerElement.data('select-months'),
      selectYears: datePickerElement.data('select-years')
    };
    const containerElement =
      datePickerElement.closest('.field').find('.pickadate-container');
    if (window.appHelpers.isMobile()) {
      this.options['container'] = 'body';
    } else if (containerElement.length > 0) {
      this.options['container'] = containerElement;
    }

    return datePickerElement.pickadate(this.options);
  }

  renderRange(datePickerElement) {
    const _this = this;
    this.options = {
      alwaysShowCalendars: true,
      applyButtonClasses: 'primary',
      buttonClasses: 'small button no-margin-bottom',
      cancelButtonClasses: 'clear',
      locale: {
        firstDay: (companyCalendarStart === 'Monday') + 0,
        format: 'MMM D, YYYY'
      },
      opens: 'center',
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days')
        ],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ]
      }
    };

    if (datePickerElement.data('opens')) {
      this.options['opens'] = datePickerElement.data('opens');
    }

    if (datePickerElement.data('mindate')) {
      this.options['minDate'] = datePickerElement.data('mindate');
    }

    if (datePickerElement.data('maxspan')) {
      this.options['maxSpan'] = { 'days': datePickerElement.data('maxspan') };
    }

    return datePickerElement.daterangepicker(this.options);
  }
};

$(document).ready(function() {
  if (!($('[data-behavior~=datepicker]').length > 0)) { return; }
  const datePickers = new App.DatePicker('[data-behavior~=datepicker]');
  return datePickers.render();
});
