class App.Signature
  constructor: (@selector) ->
    this.form = $(@selector).closest('form')
    this.canvas = $(@selector).get(0)

  render: ->
    _this = this
    this.signaturePad = new SignaturePad.default(this.canvas)
    this.signaturePad.addEventListener 'endStroke', () ->
      _this.updateField()

    this.resizeCanvas()
    return

  resizeCanvas: ->
    ratio = Math.max(window.devicePixelRatio or 1, 1)
    this.canvas.width = this.canvas.offsetWidth * ratio
    this.canvas.height = this.canvas.offsetHeight * ratio
    this.canvas.getContext('2d').scale ratio, ratio
    return

  clear: ->
    this.signaturePad.clear()
    this.updateField()

  undo: ->
    data = this.signaturePad.toData()
    if data
      data.pop()
      this.signaturePad.fromData data
      this.updateField()

  updateField: ->
    this.form.find('input#signature_base64_data').val(
      this.signaturePad.toDataURL()
    )

$(document).ready ->
  return unless $('[data-behavior~=signature-pad]').length
  signaturePad = new App.Signature('[data-behavior~=signature-pad]')
  signaturePad.render()

  $(window).resize ->
    signaturePad.resizeCanvas()

  $('#waiver-modal').on 'open.zf.reveal', ->
    # 500: duration of motion-ui effects when opening the modal
    window.setTimeout (->
      signaturePad.resizeCanvas()
      return
    ), 500

  $('[data-action=clear-signature]').click (e) ->
    signaturePad.clear()
    e.preventDefault()

  $('[data-action=undo-signature]').click (e) ->
    signaturePad.undo()
    e.preventDefault()
