class App.Confirm
  constructor: (@rails, @event) ->
    this.defaults =
      modalClass: 'text-center'
      title: 'Are you sure?'
      titleClass: ''
      body: 'This action cannot be undone.'
      bodyClass: ''
      icon: ''
      footerClass: ''
      ok: 'Confirm'
      okClass: 'primary button'
      cancel: 'Cancel'
      cancelClass: 'secondary button'
    if !this.allowAction(@event.target)
      @rails.stopEverything(@event)

  allowAction: (element) ->
    if element.getAttribute('data-confirm-modal') == null
      return true

    this.showConfirmationDialog(element)
    return false

  showConfirmationDialog: (element) ->
    _this = this
    settings = $.extend({}, this.defaults)
    elOptions = $(element).data 'confirm-modal'

    option = (name) ->
      elOptions[name] or settings[name]

    modal = $('<div data-reveal
        id=\'confirm-dialog\'
        class=\'reveal ' + option('modalClass') + '\'
        data-animation-in="slide-in-down"
        data-animation-out="slide-out-down">\n
      <h2 data-confirm-title class=\'' + option('titleClass') + '\'></h2>\n
      <div data-confirm-icon></div>\n
      <p data-confirm-body class=\'' + option('bodyClass') + '\'></p>\n
      <div data-confirm-footer class=\'' + option('footerClass') + '\'>\n
        <a data-confirm-cancel class=\'' + option('cancelClass') + '\'></a>\n
      </div>\n
    </div>')
    confirmButton =
      if $(element).is('a') then $(element).clone() else $('<a/>')
    confirmButton.removeAttr('data-confirm-modal')
    confirmButton
    .attr('class', option('okClass'))
    .html(option('ok'))
    .on 'click', (e) ->
      if $(this).prop('disabled')
        return false
      $(element).trigger 'confirm.reveal', e
      if $(element).is('form, :input')
        $(this).attr('data-style', 'zoom-in')
        $(this).addClass('disabled')
        $(this).prop('disabled')
        Ladda.create(this).start()
        return $(element).closest('form').removeAttr('data-confirm-modal').submit()
      return
    modal.find('[data-confirm-title]').html option('title')
    modal.find('[data-confirm-body]').html option('body')
    modal.find('[data-confirm-icon]').html option('icon')
    modal.find('[data-confirm-cancel]')
    .html(option('cancel'))
    .on 'click', (e) ->
      modal.foundation 'close'
      $(element).trigger 'cancel.reveal', e
      Ladda.stopAll()
    modal.find('[data-confirm-footer]').append confirmButton
    modal
    .appendTo($('body'))
    .foundation()
    .foundation('open')
    .on 'closed.fndtn.reveal', (e) ->
      modal.remove()
