import { ApplicationController } from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'errorMessage',
    'form',
    'submitButton'
  ]

  submit(event) {
    event.preventDefault()
    Ladda.create(this.submitButtonTarget).start()
    $.ajax({
      method: 'post',
      url: $(this.formTarget).attr('action'),
      data: $(this.formTarget).serialize(),
      dataType: 'json',
      success: (result) => {
        if (result.no_charge) {
          window.location.replace(result.redirect_url)
        } else {
          this.stripe.redirectToCheckout({ sessionId: result.sessionId })
        }
      },
      error: (response) => {
        if (response.responseJSON.message == 'V3 reCAPTCHA has failed!' || response.responseJSON.message == 'HumanVerification failed') {
          window.location.replace(response.responseJSON.redirect_url)
        } else {
          Ladda.stopAll()
          this.toggleError(response.responseJSON.message)
        }
      }
    })
  }

  toggleError(message) {
    if (message.length) {
      this.errorMessageTarget.innerHTML = message
      this.errorMessageTarget.classList.remove('hide')
    } else {
      this.errorMessageTarget.innerHTML = ''
      this.errorMessageTarget.classList.add('hide')
    }
  }
}
