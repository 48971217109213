import { Controller } from '@hotwired/stimulus'
import * as Ladda from 'ladda'
import ReadSmore from 'read-smore/dist/index.esm'
import PullToRefresh from 'pulltorefreshjs'
import { isNativeAppWrapper } from '../../utils/is-native-app-wrapper'

export class ApplicationController extends Controller {
  initialize() {
    if (window._pulltorefresh == undefined) {
      window._pulltorefresh = this.initPullToRefresh()
    }

    this.initReadSmore()

    this.initLaddaButtons()
  }

  initLaddaButtons() {
    Array.from(document.querySelectorAll('button[type=submit]:not(.no-ladda)')).forEach((button) => {
      button.setAttribute('data-style', 'zoom-in')
      Ladda.bind(button)
    })
  }

  initPullToRefresh() {
    if(isNativeAppWrapper()){ return }
    PullToRefresh.init({
      mainElement: 'body',
      onRefresh() {
        window.location.reload()
      }
    });
  }

  initReadSmore() {
    // target all read smore elements
    const readSmores = document.querySelectorAll('.js-read-smore')

    const readSmoreOptions = {
      wordsCount: 100
    }

    // init read smore
    ReadSmore(readSmores, readSmoreOptions).init()
  }

  get stripe() {
    if (this._stripe == undefined) {
      this._stripe = Stripe(
        stripeKey,
        {
          stripeAccount: stripeAccountKey
        }
      )
    }
    return this._stripe
  }
}
