import { ApplicationController } from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'form',
    'filterSelector',
    'clearFilterButton',
    'fallbackFilterButton'
  ]

  fallbackFilterButtonTargetConnected(target) {
    target.classList.add('hide')
  }

  fallbackFilterButtonTargetDisconnected(target) {
    target.classList.remove('hide')
  }

  filterSelected() {
    // Do not show "clear filters" button if embedded because some clients don't want their filters removed by customers
    // https://linear.app/punchpass/issue/PUN-544/public-schedule-if-there-are-any-filters-enabled-show-a-buttonlink-to

    // Does every filterSelector have no selection? - i.e. Are there no filters selected?
    if (this.filterSelectorTargets.every(this.isDefaultFilterSelection)) {
      this.clearFilterButtonTarget.style.display = 'none'
    } else if (!this.isEmbed()) {
      this.clearFilterButtonTarget.style.display = 'inline-block'
    }

    this.fallbackFilterButtonTarget.click()
  }

  isDefaultFilterSelection(target) {
    // '0' indicates no filter is selected for the selector
    return target.selectize.getValue() == '0'
  }

  isEmbed(){
    const queryParams = new URL(window.location).searchParams
    return queryParams.get("embed") == "true"
  }
}
