class App.Form
  constructor: () ->
    @foundationPresent = typeof(Foundation) != "undefined"
    @selectizePresent = typeof(Selectize) != "undefined"

    @selectors =
      abideForm: 'form[data-abide]'
      accordianCheckbox: '[data-behavior~=accordian-checkbox]'
      clearAllCheckboxes: '[data-behavior~=clear-all-checkboxes]'
      hasHeaderSubmit: 'form[data-behavior~=has-header-submit]'
      headerSubmitButton: '#page-header button[type="submit"]'
      noKeyboard: 'form select[data-behavior~=no-keyboard]'
      optionalFields: '[data-type~=optional-fields]'
      optionalFieldsInput: '[data-type~=optional-fields] :input'
      selectableCheckbox: 'form #selectable input[type=checkbox]'
      selectAllCheckboxes: '[data-behavior~=select-all-checkboxes]'
      submitForm: '[data-behavior~=submit-form]'
      toggleOptionalFields: '[data-behavior~=toggle-optional-fields]'
      toggleStateHidden: '[data-toggle-state~=hidden]'
      toggleStateShown: '[data-toggle-state~=shown]'

  # Public API

  render: ->
    @renderAbide()
    @renderAccordianCheckbox()
    @renderClearAllCheckboxes()
    @renderHasHeaderSubmit()
    @renderSelectableCheckbox()
    @renderSelectAllCheckboxes()
    @renderSelectized()
    @renderSubmitForm()
    @renderToggleStateShown()
    @renderToggleOptionalFields()

  setInputValue: (element) ->
    $el = $(element)
    $('input#' + $el.data('input-id')).val($el.data('value'))
    $('input#' + $el.data('kid-id')).val($el.data('kid-value'))
    if $el.data('submit')
      $el.parents('form').submit()

  # Private API

  fetchAccordianFields: ($el) ->
    $('#' + ($el.data('fields').id || '').split(',').join(',#'))

  fetchOptionalFields: ($el) ->
    optionalFields = $el.closest('.field').find(@selectors.optionalFields)
    # set for Foundation forms
    if optionalFields.length == 0
      optionalFields = $('#' + $el.data('fields').id)
    optionalFields

  fetchSelectableCheckboxes: (el) ->
    $($(el).data('selectable')).find('input[type=checkbox]')

  foundationPresent: ->
    typeof(Foundation) != "undefined"

  hasExpectedValue: ($el) ->
    expectedValues = ($el.data('expected-values') + '').split(',')
    $.inArray($el.val(), expectedValues) != -1

  hasExpectedValues: ($el) ->
    typeof $el.data('expected-values') != 'undefined'

  hide: ($el) ->
    $el.slideUp('slow')

  renderAbide: () ->
    self = this
    abideForms = $(@selectors.abideForm)
    abideForms.on 'submit', (e) ->
      $(this).foundation('validateForm')
      if !$(this).data('valid')
        # scroll to the first invalid input (minus 115 for sticky header)
        $('html, body').animate(
          { scrollTop: $('.is-invalid-label').first().offset().top-115 },
          'slow'
        )
        e.preventDefault()

    # validate forms to show errors when server-side validation failed
    abideForms.each ->
      if $(this).find('.form-validation-alert').length
        $(this).foundation('validateForm')

    $(document).on 'formvalid.zf.abide', (ev, elem) ->
      $(elem).data('valid', true)

    $(document).on 'forminvalid.zf.abide', (ev, form) ->
      $(form).data('valid', false)
      Ladda.stopAll()

  renderAccordianCheckbox: ->
    self = this

    $(@selectors.accordianCheckbox).on 'change', ->
      $el = $(this)
      $fields = self.fetchAccordianFields($el)
      self.toggle($fields)

    $(@selectors.accordianCheckbox).each ->
      $el = $(this)
      $fields = self.fetchAccordianFields($el)
      if $el.prop('checked')
        self.toggle($fields)

  renderClearAllCheckboxes: ->
    self = this
    # 'Clear all'
    $(@selectors.clearAllCheckboxes).on 'click', (e) ->
      e.preventDefault()
      self.fetchSelectableCheckboxes(this).prop('checked', false).trigger('change')

  renderHasHeaderSubmit: ->
    self = this
    # change state on main 'Save' button
    $(@selectors.hasHeaderSubmit).on 'submit', ->
      if $(this).data('valid')
        Ladda.create(document.querySelector(self.selectors.headerSubmitButton)).start()
        formButton = this.querySelector('button[type=submit], input[type=submit]')
        if (formButton != null)
          Ladda.create(formButton).start()
      else
        Ladda.stopAll()

  renderSelectableCheckbox: ->
    # add .not-selected class to collection checkboxes
    self = this

    $(@selectors.selectableCheckbox).each ->
      self.toggleLabelsClassForCheckboxes($(this))

    $(@selectors.selectableCheckbox).on 'change', ->
      self.toggleLabelsClassForCheckboxes($(this))

  renderSelectAllCheckboxes: ->
    self = this
    # 'Select all'
    $(@selectors.selectAllCheckboxes).on 'click', (e) ->
      e.preventDefault()
      self.fetchSelectableCheckboxes(this).prop('checked', true).trigger('change')

  renderSelectized: ->
    # selectized selects
    if @selectizePresent and @foundationPresent
      $('form select.selectize--with-caption').selectize
        dataAttr: 'data-extra'
        maxOptions: 99999
        valueField: 'id'
        render:
          option: (data, escape) ->
            optionJSON = JSON.parse(data.text)
            '<div class="option">' + '<span class="option__name">' + escape(optionJSON.name) + '</span>' + '<span class="option__caption">' + escape(optionJSON.caption) + '</span>' + '</div>'
          item: (data, escape) ->
            itemJSON = JSON.parse(data.text)
            '<div class="item">' + escape(itemJSON.name) + '</div>'
        onInitialize: () ->
          if this.$input[0].dataset['allowemptyoption']
            this.addOption({value: "", text: this.$input[0].dataset['emptyoptionlabel']})
            this.refreshOptions(false)
          event = new Event('initialize', { bubbles: true })
          this.$input[0].dispatchEvent(event)

      $('form select').not('.selectize--with-caption').not('.picker__select--year').not('.picker__select--month').not('.public-calendar-filter').selectize
        dataAttr: 'data-extra'
        maxOptions: 99999

        onChange: ->
          # allowing bubbling of events from Selectize fields so we can capture them with Stimulus
          event = new Event('change', { bubbles: true })
          this.$input[0].dispatchEvent(event)

        onInitialize: () ->
          if this.$input[0].dataset['allowemptyoption']
            this.addOption({value: "", text: this.$input[0].dataset['emptyoptionlabel']})
            this.refreshOptions(false)
          event = new Event('initialize', { bubbles: true })
          this.$input[0].dispatchEvent(event)

      if Foundation.MediaQuery.is('small only')
        $(@selectors.noKeyboard).each ->
          $(this).siblings('.selectize-control')
            .find('.selectize-input input')
            .attr('readonly','readonly')

      # This is to solve the problem of class links that are underneath an open
      # select dropdown being clicked when a select option is clicked. When the
      # select dropdown is opened, we disable all the calendar class links and
      # when the select dropdown is closed we re-enable them.
      $('.public-calendar-filter').selectize
        onDropdownOpen: ->
          $('.box').each ->
            $(this).addClass('prevent-clicking')

        onDropdownClose: ->
          $('.box').each ->
            $(this).removeClass('prevent-clicking')

  renderSubmitForm: ->
    self = this
    # submit form from page header submit button
    $(@selectors.submitForm).on 'click', ->
      $(self.selectors.hasHeaderSubmit).submit()

    # empty hidden optional fields
    $('form').on 'submit', ->
      $(this).find(self.selectors.optionsFieldsInput).each ->
        $el = $(this)
        if !$el.hasClass('selectized')
          if !$el.is(':visible')
            $el.val('')
        else
          if !$el.siblings('.selectize-control').is(':visible')
            $el.val('')

  renderToggleOptionalFields: ->
    self = this
    # toggling optional fields visibility
    $(@selectors.toggleOptionalFields).unbind 'change'
    $(@selectors.toggleOptionalFields).on 'change', ->
      $el = $(this)
      $optionalFields = self.fetchOptionalFields($el)

      if self.hasExpectedValues($el)
        # triggered by a <select> or radio buttons
        if self.hasExpectedValue($el)
          self.show($optionalFields)
        else
          self.hide($optionalFields)
      else
        # triggered by a checkbox
        self.toggle($optionalFields)

  renderToggleStateHidden: ->
    # showing optional fields when required
    $(@selectors.toggleStateHidden).each ->
      $(this).hide()

  renderToggleStateShown: ->
    # showing optional fields when required
    $(@selectors.toggleStateShown).each ->
      $(this).show()

  show: ($el) ->
    $el.slideDown('slow')

  toggle: ($el) ->
    $el.slideToggle('slow')

  toggleLabelsClassForCheckboxes: ($el) ->
    $el.closest('.field').find('.label-text').toggleClass('not-selected', !$el.is(':checked'))

$(document).ready ->
  return unless $('form').length > 0
  window.punchpassForm = new App.Form()
  window.punchpassForm.render()

  $(document).on 'open.zf.reveal', ->
    window.punchpassForm.render()
