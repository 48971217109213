// import { Controller } from '@hotwired/stimulus'
import { ApplicationController } from './application_controller'

export default class extends ApplicationController {
  static targets = [
    'cardElement',
    'disabledJavaScriptNotice',
    'errorMessage',
    'inputIdealBank',
    'inputEmail',
    'inputStreetAddress',
    'inputCity',
    'inputState',
    'inputZipCode',
    'inputCountry',
    'javascriptRequired',
    'form',
    'paymentMethod',
    'paymentMethodsTabs',
    'submitButton',
    'stripeIdealElement'
  ]

  connect() {
    this.disabledJavaScriptNoticeTarget.parentNode.removeChild(
      this.disabledJavaScriptNoticeTarget
    )
    this.javascriptRequiredTargets.forEach((el, i) => {
      el.classList.remove('hide')
    })
    if (typeof Stripe != 'undefined') {
      this.initializeCardElement()
      this.initializeIdealElement()
    }
  }

  initialize() {
    if (this.hasPaymentMethodsTabsTarget) {
      this.paymentMethodTarget.value =
        this.paymentMethodsTabsTarget.querySelector('li.is-active > a').getAttribute('data-payment-method')
    } else {
      this.paymentMethodTarget.value = 'card'
    }
  }

  addTokenInput(token_value) {
    const tokenElement = document.createElement('input')
    tokenElement.setAttribute('type', 'hidden')
    tokenElement.setAttribute('name', 'stripeToken')
    tokenElement.setAttribute('value', token_value)
    this.formTarget.appendChild(tokenElement)
  }

  handleCreatedSource(data) {
    if (data.stripe_source_id) {
      this.stripe.retrieveSource({
        id: data.stripe_source_id,
        client_secret: data.stripe_client_secret
      }).then((result) => {
        if (result.error) {
          Honeybadger.notify(result.error)
          this.toggleError(result.error.message)
        } else {
          switch (result.source.flow) {
            case 'redirect':
              window.location.replace(result.source.redirect.url)
          }
        }
      })
    } else {
      // fully discounted purchase
      this.cardElementTarget.remove()
      this.formTarget.submit()
    }
  }

  initializeCardElement() {
    if (this.hasCardElementTarget) {
      this.card.mount('#card-element')
      this.card.addEventListener('change', (event) => {
        if (event.error) {
          this.toggleError(event.error.message)
        } else {
          this.toggleError('')
        }
      })
    }
  }

  initializeIdealElement() {
    if (this.hasStripeIdealElementTarget) {
      const idealBank = this.elements.create('idealBank',
        {
          style: { base: Object.assign({padding: '10px 15px'}, this.style.base) },
        }
      );
      idealBank.mount('#ideal-bank-element');
      idealBank.on('change', (event) => {
        this.inputIdealBankTarget.value = event.value
      })
    }
  }

  setMethod(event) {
    this.paymentMethodTarget.value = event.target.getAttribute('data-payment-method')
    this.toggleError('')
  }

  submit(event) {
    event.preventDefault()
    Ladda.create(this.submitButtonTarget).start()
    console.log(this.paymentMethodTarget.value)
    if (this.paymentMethodTarget.value === 'card') {
      this.submitWithCard()
    } else {
      this.submitWithSource()
    }
  }

  submitWithSource() {
    this.formTarget.querySelector('#pass_status').value = 'pending'
    $.ajax({
      method: 'post',
      url: purchaseUrl,
      data: $(this.formTarget).serialize(),
      dataType: 'json',
      success: (data) => {
        this.handleCreatedSource(data)
      },
      error: (xhr) => {
        this.formTarget.querySelector('#pass_status').value = 'received'
        this.toggleError(JSON.parse(xhr.responseText).message)
      }
    })
  }

  submitWithCard() {
    if (railsEnvironment === 'test') {
      // check options and submit
      const options = this.options
      this.formTarget.submit()
    } else if (!this.hasCardElementTarget) {
      // fully discounted, no #card-element
      this.formTarget.submit()
    } else {
      // create Stripe token
      this.stripe.createToken(this.card, this.options).then((result) => {
        if (result.error) {
          this.toggleError(result.error.message)
        } else {
          this.addTokenInput(result.token.id)
          this.formTarget.submit()
        }
      })
    }
  }

  toggleError(message) {
    if (message.length) {
      this.errorMessageTarget.innerHTML = message
      this.errorMessageTarget.classList.remove('hide')
      Ladda.stopAll()
    } else {
      this.errorMessageTarget.innerHTML = ''
      this.errorMessageTarget.classList.add('hide')
    }
  }

  get card() {
    if (this._card == undefined && this.hasCardElementTarget) {
      this._card = this.elements.create('card',
        {
          hidePostalCode: true,
          iconStyle: 'default',
          style: this.style
        }
      )
    }
    return this._card
  }

  get elements() {
    if (this._elements == undefined && this.hasCardElementTarget) {
      this._elements = this.stripe.elements()
    }
    return this._elements
  }

  get options() {
    const options = {}
    if (this.data.get('name')) {
      // logged-in customer
      options.name = this.data.get('name')
    } else {
      // visitor on public pages
      options.name = this.inputEmailTarget.value
    }
    if (this.data.get('has-address') == 'true') {
      options.address_line1 = this.inputStreetAddressTarget.value
      options.address_city = this.inputCityTarget.value
      options.address_state = this.inputStateTarget.value
      options.address_zip = this.inputZipCodeTarget.value
      options.address_country = this.inputCountryTarget.value
    } else if (this.data.get('has-zip') == 'true') {
      options.address_zip = this.inputZipCodeTarget.value
    }
    return options
  }

  get style() {
    return {
      base: {
        iconColor: '#666ee8',
        color: '#555555',
        fontWeight: 400,
        fontFamily:
          'Lato, Helvetica, Roboto, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#999',
        },
        ':-webkit-autofill': {
          color: '#fce883',
        },
      },
    }
  }
}
