import { ApplicationController } from './application_controller'
import { animations } from '../../animations'

export default class extends ApplicationController {
  static targets = [
    'maybeFailedMessage',
    'failedMessage',
    'failureReason',
    'pendingMessage',
    'successMessage',
    'ticket'
  ]

  initialize() {
    if (typeof Stripe != 'undefined') {
      this.setTicketClass('warning')
      this.pollForPurchaseStatus()
    }
  }

  pollForPurchaseStatus() {
    $.ajax({
      method: 'get',
      url: '/purchases/' + this.data.get('guid'),
      dataType: 'json',
      success: (purchase) => {
        this.updateTicket(purchase)
      },
      error: (response) => {
        Honeybadger.notify('pollForPurchaseStatus() errored', {context: {response: response}})
        this.toggleError(response.responseJSON.message)
      }
    })
  }

  hidePurchaseConfirmation() {
    const purchaseConfirmationElement =
      document.querySelector('#purchase-confirmation')
    // animations.animateCss(
    //   purchaseConfirmationElement,
    //   'fadeOut',
    //   (() => { purchaseConfirmationElement.classList.add('hide') })
    // )
    purchaseConfirmationElement.classList.add('hide')
  }

  showMessage(messageTarget) {
    this.pendingMessageTarget.classList.add('hide')
    this.maybeFailedMessageTarget.classList.add('hide')
    this.failedMessageTarget.classList.add('hide')
    this.successMessageTarget.classList.add('hide')
    messageTarget.classList.remove('hide')
  }

  setTicketClass(status) {
    this.ticketTarget.classList.toggle(status)
  }

  updateTicket(purchase) {
    if (purchase.payment_status == 'failed') {
      this.showMessage(this.failedMessageTarget)
      this.ticketTarget.classList.remove('warning')
      this.setTicketClass('alert')
      this.hidePurchaseConfirmation()
    } else if (purchase.payment_status == 'paid') {
      this.showMessage(this.successMessageTarget)
      this.ticketTarget.classList.remove('warning')
      this.setTicketClass('success')
      if ($('#reservation-details').length > 0) {
        $('#reservation-details').removeClass('hide')
      }
    } else if (this.pollCount < this.maxPollCount) {
      this.showMessage(this.pendingMessageTarget)
      if (!this.ticketTarget.classList.contains('warning')) {
        this.setTicketClass('warning')
      }
      this.pollCount += 1
      window.setTimeout(() => {
        this.pollForPurchaseStatus()
        return
      }, 500)
    }
  }

  toggleError(message) {
    this.showMessage(this.maybeFailedMessageTarget)
    this.setTicketClass('alert')
    this.hidePurchaseConfirmation()
  }

  get pollCount() {
    return parseInt(this.data.get('poll-count') || 0)
  }

  set pollCount(value) {
    this.data.set('pollCount', value)
  }

  get maxPollCount() {
    return 15
  }
}
