# inspiration: https://github.com/zoxxx/GDPR-cookie-consent
require '../vendor/cookieconsent.min'

class CookieManagement
  activeForAll: false
  consented: document.cookie.match(/cookieconsent_status=allow/) ? true : false
  consentQueue: []
  isEu: false

  constructor: ->
    janOffset = new Date(new Date().getFullYear(), 0, 1).getTimezoneOffset()
    julOffset = new Date(new Date().getFullYear(), 6, 1).getTimezoneOffset()

    if @activeForAll or (janOffset >= -120 and janOffset <= 0 and julOffset < janOffset)
      @isEu = true

  queue: (fn) =>
    if @isEu and !@consented
      @consentQueue.push fn
    else
      fn()

  onConsent: ->
    @consentQueue.forEach (fn) -> fn()

  onWindowLoad: =>
    if @isEu and !window.isMobileApp
      window.cookieconsent.initialise(
        onInitialise: @processChoice
        onStatusChange: @processChoice
        palette:
          popup:
            background: "#edf2f3"
            link: "#067e81"
          button:
            background: "#067e81"
        theme: "classic"
        type: "opt-in"
        content:
          message: "We would like to use cookies to ensure you get the best possible experience.",
          href: "https://punchpass.com/privacy"
      )

  processChoice: (status) =>
    if status == 'allow'
      @consented = true
      @onConsent()
    else
      @consented = false

window.cookieManagement = new CookieManagement
window.addEventListener "load", window.cookieManagement.onWindowLoad
