class App.Helpers
  constructor: ->

  animateCSS: (node, animationName, callback) ->
    handleAnimationEnd = ->
      node.classList.remove 'animated', animationName
      node.removeEventListener 'animationend', handleAnimationEnd
      if typeof callback == 'function'
        callback()
      return
    node.classList.add 'animated', animationName
    node.addEventListener 'animationend', handleAnimationEnd
    return

  isMobile: ->
    navigator.userAgent.match(/Android/i) or
    navigator.userAgent.match(/webOS/i) or
    navigator.userAgent.match(/iPhone/i) or
    navigator.userAgent.match(/iPad/i) or
    navigator.userAgent.match(/iPod/i) or
    navigator.userAgent.match(/BlackBerry/i) or
    navigator.userAgent.match(/Windows Phone/i)

$(document).ready ->
  # 'Copy to clipboard' links
  $('[data-behavior~=copy-to-clipboard]').click (e) ->
    e.preventDefault()
    navigator.clipboard.writeText($($(this).data('copy-element')).html())
    # This copy alert will happen the "Copy to clipboard" text is not visible. i.e. variables when editing e-mail templates
    if $(this).data('copy-alert')
      this.insertAdjacentHTML 'afterend', "<p class='clipboard-alert'>" + $(this).data('copied') + "</p>"
      setTimeout (->
        document.querySelectorAll('.clipboard-alert').forEach (clipboardAlert) ->
          clipboardAlert.remove()
      ), 1000
    # This is for when the "Copy to clipboard" text is visible and is changed temporily by what's passed in the "copied" data attribute
    $(this).fadeTo 100, 0.25, ->
      if $(this).data('copied')
        $(this).find('span').html($(this).data('copied'))
      $(this).fadeTo 500, 1.0
    e.stopPropagation()
