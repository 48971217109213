/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
//= require pickadate/lib/compressed/picker
//= require pickadate/lib/compressed/picker.time

App.TimePicker = class TimePicker {
  constructor(selector) {
    this.selector = selector;
    this.timePickerElements = $(this.selector);
  }

  render() {
    const _this = this;
    return this.timePickerElements.each(function() {
      if (typeof companyInternationalTime !== 'undefined') {
        let timePickerFormat, timePickerFormatLabel;
        if (companyInternationalTime) {
          timePickerFormat = 'H:i';
          timePickerFormatLabel = 'H:i';
        } else {
          timePickerFormat = 'h:i A';
          timePickerFormatLabel = 'h:i A';
        }
        return $(this).pickatime({
          container: $(this).closest('.field').find('.pickadate-container'),
          interval: 5,
          format: timePickerFormat,
          formatLabel: timePickerFormatLabel,
          formatSubmit: 'HH:i',
          hiddenPrefix: 'prefix__',
          hiddenSuffix: '__suffix'
        });
      } else {
        return $(this).pickatime({
          container: $(this).closest('.field').find('.pickadate-container'),
          interval: 5
        });
      }
    });
  }
};

$(document).ready(function() {
  if (!($('[data-behavior~=timepicker]').length > 0)) { return; }
  const timePickers = new App.TimePicker('[data-behavior~=timepicker]');
  return timePickers.render();
});
