// This is the entry point for all the "public app"-specific Stimulus Controllers
// This is different than the "admin app" set of controllers

import { application } from "../application"

import {ApplicationController} from "./application_controller"
application.register("application", ApplicationController)

import BottomBarController from "./bottom_bar_controller"
application.register("bottom-bar", BottomBarController)

import DiscountButtonController from "./discount_button_controller"
application.register("discount-button", DiscountButtonController)

import FormController from "./form_controller"
application.register("form", FormController)

import InfiniteScheduleController from './infinite_schedule_controller'
application.register('infinite-schedule', InfiniteScheduleController)

import PaymentsController from "./payments_controller"
application.register("payments", PaymentsController)

import PublicController from "./public_controller"
application.register("public", PublicController)

import PublicIntakeFormsController from "./public_intake_forms_controller"
application.register("public-intake-forms", PublicIntakeFormsController)

import PurchaseStatusController from "./purchase_status_controller"
application.register("purchase-status", PurchaseStatusController)

import ScheduleFiltersController from "./schedule_filters_controller"
application.register("schedule-filters", ScheduleFiltersController)

import StripeCheckoutController from "./stripe_checkout_controller"
application.register("stripe-checkout", StripeCheckoutController)

import VimeoPlayerController from "./vimeo_player_controller"
application.register("vimeo-player", VimeoPlayerController)

import NavigableDialogController from './navigable_dialog_controller'
application.register("navigable-dialog", NavigableDialogController)
