import { Controller } from '@hotwired/stimulus'
import Player from '@vimeo/player';

export default class extends Controller {
  static targets = []

  initialize() {
    const videoId = this.data.get('id')
    const videoPlayer = new Player('vimeo-player-' + videoId, {
      id: parseInt(videoId),
      width: parseInt(this.data.get('width')),
      responsive: true
    })
  }
}
