class App.Discount
  constructor: (@selector) ->
    this.discountButton = $(@selector)

  render: ->
    _this = this
    @discountButton.attr 'data-style', 'zoom-in'
    @discountButton.click (e) ->
      e.preventDefault()
      Ladda.create(this).start()
      form = _this.discountButton.parents('form')
      if form.attr('id').search(/customer/) > -1
        searchParams = _this.getFormParams form, 'customer'
      else if form.attr('id').search(/pass/) > -1
        searchParams = _this.getFormParams form, 'pass'
      # reload page with args
      window.location.search += '&' + searchParams.join('&')

  # returns all form params as an array of strings - eg.
  # ["pass[first_name]=Michael", "pass[email]=michael@punchpass.com"]
  getFormParams: (form, objectName) ->
    serializedParams =
    form.serializeArray().filter (el) ->
      elementNameRegex = new RegExp('^' + objectName + '\\\[')
      return el.name.match(elementNameRegex)
    formParams = []
    $.each serializedParams, (index, formParam) ->
      formParamNameRegex = new RegExp(objectName + '\\\[(.*)\\\]')
      formParamName = formParam.name.match(formParamNameRegex)[1]
      formParams[formParamName] = formParam.value
    return Object.keys(formParams).map (i) ->
      return objectName + "[" + i + "]=" + encodeURIComponent(formParams[i])

$(document).ready ->
  return unless $('[data-behavior~=apply-discount]').length
  window.discount = new App.Discount('[data-behavior~=apply-discount]')
  window.discount.render()
