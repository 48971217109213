// English

jQuery.extend( jQuery.fn.pickadate.defaults, {
    monthsFull: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
    monthsShort: [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ],
    weekdaysFull: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
    weekdaysShort: [ 'sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat' ],
    today: 'Today',
    clear: 'Clear',
    close: 'Close',
    firstDay: 1,
    format: 'dddd d !of mmmm !of yyyy',
    formatSubmit: 'yyyy/mm/dd',
    hiddenName: true
});

jQuery.extend( jQuery.fn.pickatime.defaults, {
    clear: 'Clear'
});
