import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'cancelButton',
    'submitButton',
    'serializedDataField'
  ]

  initialize() {

  }

  submitForm(event) {
    event.preventDefault();

    this.enableActions(false);

    if (this.validInput()) {
      this.serializeData();
      this.formTarget.submit();
    } else {
      this.enableActions(true);
    }
  }

  validInput() {
    let valid = true;
    const fields = document.querySelectorAll(".intake-form-field");
    const errorContainer = document.querySelector("#error-container");

    fields.forEach((field) => {
      // hack for selectized generated copies
      const fieldId = field.dataset.intakeFormFieldId;
      if (fieldId === undefined)
        return;

      const required = field.dataset.fieldRequired;
      if(required == 'false')
        return;

      const fieldLabel = document.querySelector(`#intake_form_field_${fieldId}_label`);

      if (field.value) {
        field.classList.remove('is-invalid-input');
        fieldLabel.classList.remove('is-invalid-label');
      } else {
        field.classList.add('is-invalid-input');
        fieldLabel.classList.add('is-invalid-label');
        valid = false;
      }
    });

    if (valid) {
      errorContainer.style.display = "none";
    } else {
      errorContainer.style.display = "flex";
      Foundation.SmoothScroll.scrollToLoc("#error-container");
    }

    return valid;
  }


  serializeData() {
    const fields = document.querySelectorAll(".intake-form-field");
    let serializedFields = [];

    fields.forEach((field) => {
      // hack for selectized generated copies
      const fieldId = field.dataset.intakeFormFieldId;
      if (fieldId === undefined)
        return;

      serializedFields.push({
        intakeFormFieldId: fieldId,
        value: field.value
      });
    });

    let serializedData = JSON.stringify(serializedFields);
    this.serializedDataFieldTarget.value = serializedData;
  }

  enableActions(enabled) {
    if (enabled) {
      this.submitButtonTarget.removeAttribute('disabled');
      this.submitButtonTarget.removeAttribute('data-loading');
      this.cancelButtonTarget.removeAttribute('disabled');
    } else {
      this.submitButtonTarget.setAttribute('disabled', '');
      this.cancelButtonTarget.setAttribute('disabled', '');
    }

    setInterval((button) => {
      const errorContainer = document.querySelector("#error-container");
      if(errorContainer.style.display != 'none') {
        if (button.attributes.getNamedItem('disabled'))
          button.attributes.removeNamedItem('disabled')
        if (button.attributes.getNamedItem('data-loading'))
          button.attributes.removeNamedItem('data-loading')
      }
    }, 1000, this.submitButtonTarget);
  }

}
