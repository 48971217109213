import { ApplicationController } from './application_controller'

export default class extends ApplicationController {
  static targets = []

  initialize() {
    $(this.element).foundation()
    this.showErrors()

    // Abide: set 'data-valid' attribute on form
    $(document).on('formvalid.zf.abide', (ev, elem) => {
      elem[0].dataset.validRecord = true
    })

    $(document).on('forminvalid.zf.abide', (ev, form) => {
      form[0].dataset.validRecord = false
    })
  }

  submit(event) {
    event.preventDefault()
    // validate form
    $(this.element).foundation('validateForm')
    // submit only if form is valid
    if (this.element.dataset.validRecord == 'true') {
      this.element.submit()
    } else {
      Ladda.stopAll()
    }
  }

  // Abide: show errors after form reload (Rails validation)
  showErrors() {
    if (this.element.querySelector('.form-validation-alert')) {
      $(this.element).foundation('validateForm')
    }
  }
}
