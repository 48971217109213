class App.PunchpassPaymentForm
  constructor: (@selector) ->
    this.form = $(@selector)

    # initialize Elements
    this.stripe = Stripe(stripeKey)
    this.elements = this.stripe.elements()

  renderElements: ->
    _this = this

    # design Elements
    style = base:
      color: '#555555'
      fontFamily: 'Lato, Helvetica, Roboto, Arial, sans-serif'
      fontSize: '16px'
      fontSmoothing: 'antialiased'
      lineHeight: '24px'
      '::placeholder': color: '#999'
      ':-webkit-autofill': color: '#fce883'

    # create Elements
    card = @elements.create('card',
      hidePostalCode: true
      iconStyle: 'default'
      style: style)

    # mount Elements in current DOM
    card.mount '#card-element'

    # show error when required
    card.addEventListener 'change', (event) ->
      if event.error
        _this.showCardError event.error.message
      else
        _this.showCardError ''
      return

    # catch submit action on the form
    @form.on 'submit', (event) ->
      # don't catch action if the token has been generated
      return if _this.form.find('input[name="stripeToken"]').length

      event.preventDefault()

      # make sure that the submit button is disabled
      # (useful if the form is submitted with <Enter>)
      Ladda.create(_this.form.find('button[type="submit"]')[0]).start()

      # create Stripe token for the card
      _this.stripe.createToken(card).then (result) ->
        if result.error
          # show raised error and re-enable submit buttons
          _this.showCardError result.error.message
          Ladda.stopAll()
        else
          # append stripToken input to form and submit
          _this.addTokenInput result.token, _this.form.attr('id')
          _this.form.submit()

  # function used to show errors for the Elements form
  showCardError: (message) ->
    @form.find('#card-errors').html message

  # function used to append stripeToken input to form
  addTokenInput: (token, formId) ->
    $('<input>').attr(
      type: 'hidden'
      name: 'stripeToken'
      value: token.id).appendTo @form

$(document).ready ->
  return unless $('[data-behavior~=punchpass-payment-form]').length > 0
  punchpassPaymentForm =
    new App.PunchpassPaymentForm $('[data-behavior~=punchpass-payment-form]')
  punchpassPaymentForm.renderElements()
