window.abideDatePresentIfExpiresAtDateValidator = ($el, _req, _parent) ->
  value = $('#passtype_pass_length').val() != "Expires on a specific date" or
    ($('#passtype_pass_length').val() == "Expires on a specific date" and
      $el.val() != '')
  value

window.abideDatePresentIfStartDateValidator = ($el, _req, _parent) ->
  value = $('#passtype_start_date_selection').val() != "true" or
    ($('#passtype_start_date_selection').val() == "true" and
      $el.val() != '')
  value

window.abideGreaterThanZeroValidator = ($el, required, _parent) ->
  if (!required)
    return true
  Number.parseFloat($el.val().split(',').join('')) > 0.0

window.abideLimitSelectedIfLimitedClass = ($el, _req, _parent) ->
  value = !$('#course_limit_attendees').is(':checked') or
    ($('#course_limit_attendees').is(':checked') and
      $el.val() != '')
  value

window.abideNumberOfPunchesSelectedIfUnlimitedPasstype = ($el, _req, _parent) ->
  !$('#passtype_pass_type_punch_card').is(':checked') or
    ($('#passtype_pass_type_punch_card').is(':checked') and
      $el.val() != '')

window.abidePassPresentIfLinkedToPass = ($el, _req, _parent) ->
  value = !$('#series_assign_specific_pass').is(':checked') or
    ($('#series_assign_specific_pass').is(':checked') and
      $el.val() != '')
  value

window.abidePassPresentIfPrivateForAttendance = ($el, _req, _parent) ->
  value = !$('#private_sessions_create_form_commitment_type_attendance').is(':checked') or
    ($('#private_sessions_create_form_commitment_type_attendance').is(':checked') and
      $el.val() != '')
  value

window.abideRoundNumber = ($el, _req, _parent) ->
  if ($el.is(":hidden"))
    return true
  value = parseFloat($el.val())
  !Number.isNaN(value) && value % 1 == 0 && value > 0

window.abideSelectedValue = ($el, _req, _parent) ->
  value = $el.val() != '' && $el.val() != null
  value

window.abideSelectedValueUnlessOnlinePayment = ($el, _req, _parent) ->
  value = $('#card-element').is(':visible') or
    ($el.val() != '' && $el.val() != null)
  value

window.abideSelectedValueUnlessOnlinePaymentOrPaymentReceived = ($el, _req, _parent) ->
  value = $('#card-element').is(':visible') or
    (!$('#pass_payment_received').is(':checked')) or
    ($('#pass_payment_received').is(':checked') && $el.val() != '' && $el.val() != null)
  value

window.abideSetIfNotAllCustomers = ($el, _req, _parent) ->
  value = $el.val() != '' && $el.val() != null
  (!value and $('#email_all_customers').is(':checked')) or value

window.abideAtLeastOneCollection = ($el, _req, _parent) ->
  value = $el.closest('.field.check_boxes').find('input[type=checkbox]:checked').length > 0 or
    ($('#resource_new_collection').val() != '' && $('#resource_new_collection').val() != null)
  value

window.abideResourceProviderSelectedIfVideo = ($el, _req, _parent) ->
  !$('#resource_content_type_video').is(':checked') or
    ($('#resource_content_type_video').is(':checked') and
      $el.closest('.field.radio_buttons').find('input[type=radio]:checked').length > 0)
