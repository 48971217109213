import { ApplicationController } from './application_controller'
import { sanitizedHTMLFragment } from '../../utils/sanitized-html-fragment'

export default class extends ApplicationController {
  static targets = [
    'instance',
    'list',
    'emptyMessage',
    'loadMoreButton',
    'filtersForm'
  ]

  followUrl(event) {
    if (event.currentTarget.dataset.url != '') {
      location.href = event.currentTarget.dataset.url
    }
  }

  filtersChanged(form){
    this.listTarget.classList.add('loading')
    this.emptyMessageTarget.classList.add('loading')
    this.listTarget.setAttribute('inert', true)
    this.emptyMessageTarget.setAttribute('inert', true)
  }

  replaceClasstimes(content){
    this.listTarget.replaceChildren(...sanitizedHTMLFragment(content))
    this.loadFinished()
  }


  appendClasstimes(content){
    this.loadMoreButtonTargets.forEach(x => x.remove())
    this.listTarget.append(...sanitizedHTMLFragment(content))
    this.loadFinished()
  }

  loadFinished(){
    this.listTarget.classList.remove('loading')
    this.emptyMessageTarget.classList.remove('loading')

    this.listTarget.removeAttribute('inert')
    this.emptyMessageTarget.removeAttribute('inert')

    if(this.listTarget.children.length <= 0){
      this.emptyMessageTarget.classList.remove('hide')
    } else {
      this.emptyMessageTarget.classList.add('hide')
    }

    this.filtersFormTargets.forEach(form => {
      form.querySelectorAll(`button[type="submit"]`).forEach(x => {
        x.removeAttribute("disabled")
        x.removeAttribute("data-loading")
      })
    })
  }
}
