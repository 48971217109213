import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'back',
    'forward',
    'refresh'
  ]

  initialize() {
    if (this.hasBackTarget && document.referrer.includes('/session')) {
      this.backTarget.classList.add(this.disabledLinkClass)
    }
  }

  goBack(e) {
    if (this.backTarget.classList.contains(this.disabledLinkClass)) {
      e.preventDefault()
    }
  }

  get disabledLinkClass() {
    return 'bottom-bar__navigation__item--disabled'
  }
}
