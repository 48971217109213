switch (window.LOCALE.substr(0,2)) {
  case 'es':
    require('./picker_localizations/es');
    break;
  case 'fr':
    require('./picker_localizations/fr');
    break;
  case 'de':
    require('./picker_localizations/de');
    break;
  case 'sv':
    require('./picker_localizations/sv');
    break;
  case 'nl':
    require('./picker_localizations/nl');
    break;
  case 'zh':
    require('./picker_localizations/zh');
    break;
  case 'ja':
    require('./picker_localizations/ja');
    break;
  case 'nn':
    require('./picker_localizations/nn');
    break;
  default:
    require('./picker_localizations/en');
    break;
}
